import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/SEO"
import ResumeLayout from "../components/ResumeLayout"
import ParallaxResume from "../components/ResumeModules/ParallaxResume"
import ResumeBanner from "../components/ResumeModules/ResumeBanner"
import { Parallax, ParallaxLayer } from "@react-spring/parallax"
import { useRef } from "react"

const ResumeTemplate = ({ contentfulPage, resume }) => {
  const headerImage = getImage(contentfulPage.headerImage)
  const parallaxHero = useRef(null)

  return (
    <>
      <Seo title={contentfulPage.title} />
      <ResumeLayout>
        <Parallax ref={parallaxHero} pages={2}>
          <ParallaxLayer offset={0} speed={2}>
            <ResumeBanner {...contentfulPage}>
              <GatsbyImage
                className="banner__image"
                image={headerImage}
                alt={`${contentfulPage.title} Banner`}
              />
            </ResumeBanner>
          </ParallaxLayer>
          <ParallaxLayer offset={1} speed={0.5}>
            {resume &&
              resume.map(({ node }) => (
                <ParallaxResume key={node.id} {...node} />
              ))}
          </ParallaxLayer>
        </Parallax>
      </ResumeLayout>
    </>
  )
}

export default ResumeTemplate
