import * as React from "react"
import { ResumeBannerStyles } from "./ResumeBannerStyles"
import RichText from "../RichText"

const ResumeBanner = ({ children, title, mainContent }) => {
  return (
    <ResumeBannerStyles>
      {title && (
        <div className="container">
          <div className="banner__content">
            <h1>{title}</h1>
            <RichText richText={mainContent} />
          </div>
        </div>
      )}
      {children}
      <div className="gradient"></div>
    </ResumeBannerStyles>
  )
}

export default ResumeBanner
