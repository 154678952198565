import * as React from "react"
import NavModule from "./NavModule/NavModule"
import { GlobalStyle } from "../styles/GlobalStyles"
import { Popover } from "@typeform/embed-react"

const ResumeLayout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <NavModule />
      {children}
      <Popover
        id="PRmMdTKz"
        medium="contact-us-form-test"
        hidden={{ foo: "foo value", bar: "bar value" }}
        buttonProps={{
          ariaLabel: "Contact Us Button",
          dataTestid: "contact-us-form-button",
        }}
        tooltip="🚀 Let's connect! "
        customIcon="https://live.staticflickr.com/65535/53062156001_4b6af272dd.jpg"
      />
    </>
  )
}

export default ResumeLayout
