import React from "react"
import { graphql } from "gatsby"
import DefaultTemplate from "../templates/default-template"
import FeedTemplate from "../templates/feed-template"
import ResumeTemplate from "../templates/resume-template"

const getTemplate = (contentfulPage, resumeData) => {
  const { template } = contentfulPage

  switch (template) {
    case "resume":
      return (
        <ResumeTemplate contentfulPage={contentfulPage} resume={resumeData} />
      )

    case "feed":
      return <FeedTemplate {...contentfulPage} />

    default:
      return <DefaultTemplate {...contentfulPage} />
  }
}

const Page = ({
  data: {
    contentfulPage,
    allContentfulResume: { edges },
  },
}) => {
  return <main>{getTemplate(contentfulPage, edges)}</main>
}

export const data = graphql`
  query pageQuery($id: String) {
    contentfulPage(id: { eq: $id }) {
      template
      title
      headerImage {
        gatsbyImageData(
          width: 2000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
      feedType
      mainContent {
        raw
      }
    }
    allContentfulResume {
      edges {
        node {
          id
          experience {
            id
            logo {
              gatsbyImageData(
                width: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            skills {
              id
              name
              image {
                file {
                  url
                }
              }
              type
            }
            roles {
              id
              name
              icon {
                gatsbyImageData(
                  width: 50
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            projects {
              id
              featureThisProduct
              description {
                raw
              }
              gatsbyPath(filePath: "/projects/{contentfulProduct.title}")
              title
              url
              headerImage {
                gatsbyImageData(
                  width: 600
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              featuredImage {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              introduction
            }
            achievements
            location
            description
            company
            end(formatString: "MMM, YYYY")
            start(formatString: "MMM, YYYY")
            title
            stickyPositionStart
            stickyPositionEnd
          }
          summary {
            summary
          }
          description {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                title
                description
                gatsbyImageData(
                  width: 400
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
                __typename
              }
            }
          }
          title
        }
      }
    }
  }
`

export default Page
