import styled from "styled-components"

export const ResumeBannerStyles = styled.section`
  height: 100vh;
  position: relative;
  padding: 30px var(--borderSpacing);
  @media (max-width: 480px) {
    height: 100svh;
  }

  .container {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .gradient,
  .banner__image {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-left: 2px solid var(--primary);
    border-bottom: 2px solid var(--primary);
    box-shadow: var(--boxShadow);

    @media (min-width: 768px) {
      top: 50%;
      transform: translate(0, -50%);
      left: unset;
      right: 32px;
      width: calc(50vw - 32px);
      height: calc(80vh - 64px);
    }
  }

  .logo__image {
    width: 380px;
  }

  .gradient {
    background: radial-gradient(
      at bottom left,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }

  .banner__content {
    position: relative;
    z-index: 2;
    min-height: 33vh;
    width: 100%;
    max-width: 700px;

    @media (min-width: 768px) {
      width: 60vw;
    }

    h1,
    h2 {
      text-shadow: var(--textShadow);
    }

    h1 {
      border-bottom: 2px solid rgba(255, 255, 255, 0.15);
      display: inline-block;
    }

    h2 {
      font-size: var(--h5);
      font-weight: 400;
    }

    h1,
    .price {
      margin-top: 0;
      font-size: var(--bannerTitle);
    }
  }

  .banner__btns {
    display: flex;
    gap: var(--gap);
  }
`
