import { ParallaxLayer } from "@react-spring/parallax"
import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FaRegCalendar, FaMapPin } from "react-icons/fa"
import Skill from "../Skills/Skill"
import Role from "../Skills/Role"
import {
  SkillsWrapperStyles,
  RolesWrapperStyles,
} from "../BasicTextModule/BasicTextModuleStyles"
import Product from "../Features/Product"
import { ProductsStyles } from "../Features/FeaturesStyles"

const Experience = ({
  skills,
  logo,
  projects,
  description,
  achievements,
  company,
  end,
  start,
  title,
  position,
  location,
  roles,
}) => {
  const alignCenter = { display: "flex", alignItems: "center" }
  const image = getImage(logo)

  return (
    <>
      <ParallaxLayer
        offset={position}
        speed={1}
        factor={1}
        style={{ ...alignCenter, justifyContent: "flex-end" }}
      >
        <div className="full content">
          <p className="time">
            <FaRegCalendar /> {start} - {end}{" "}
          </p>
          <GatsbyImage className="logo" image={image} alt="Product Image" />
          <h1>{company}</h1>
          <h2>{title}</h2>
          <span className="location">
            <FaMapPin /> {location}
          </span>
          <p>{description}</p>
          <ul className="achievements">
            {achievements?.map((achievement) => (
              <li>{achievement}</li>
            ))}
          </ul>
          <RolesWrapperStyles>
            <div className="roles">
              <div className="roles__content">
                {roles.map((role) => (
                  <Role key={role.id} {...role} />
                ))}
              </div>
            </div>
          </RolesWrapperStyles>
          <SkillsWrapperStyles>
            <div className="skills">
              <div className="skills__content">
                <p>Tech Stack</p>
                <div className="skills__showcase">
                  {skills.map((skill) => (
                    <Skill key={skill.id} {...skill} />
                  ))}
                </div>
              </div>
            </div>
          </SkillsWrapperStyles>
          <ProductsStyles>
            <p>Selected Projects</p>
            <div className="container">
              {projects.map((node, index) => {
                return <Product feature={node} key={index} />
              })}
            </div>
          </ProductsStyles>
        </div>
      </ParallaxLayer>
    </>
  )
}

export default Experience
