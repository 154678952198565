import * as React from "react"
import { Parallax, ParallaxLayer } from "@react-spring/parallax"
import { ParallaxResumeStyles } from "./ParallaxResumeStyles"
import Experience from "./Experience"
import RichText from "../RichText"

const ParallaxResume = ({
  experience,
  summary: { summary },
  description,
  title,
}) => {
  const alignCenter = { display: "flex", alignItems: "center" }
  return (
    <ParallaxResumeStyles>
      <div className="background" />
      <Parallax pages={experience.length + 1}>
        <ParallaxLayer
          offset={0}
          speed={0.5}
          style={{ ...alignCenter, justifyContent: "center" }}
        >
          <section className="intro">
            <div className="container container__tight">
              <h1>{title}</h1>
              <p>{summary}</p>
              <RichText richText={description} />
            </div>
          </section>
        </ParallaxLayer>
        {experience &&
          experience.map((exp, ind) => (
            <Experience position={ind + 1} key={exp.id} {...exp} />
          ))}
      </Parallax>
    </ParallaxResumeStyles>
  )
}

export default ParallaxResume
