import * as React from "react"
import { Link } from "gatsby"
import Button from "../Button/Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { ProductStyles } from "./FeaturesStyles"

const ProductLink = ({ featureThisProduct, children, url, gatsbyPath }) => {
  return featureThisProduct ? (
    <Link to={gatsbyPath}>{children}</Link>
  ) : (
    <a href={url} target="_blank" rel="nofollow noreferrer noopener">
      {children}
    </a>
  )
}

const Product = ({ feature }) => {
  const {
    headerImage,
    title,
    introduction,
    featureThisProduct,
    featuredImage,
  } = feature
  const image = featureThisProduct
    ? getImage(featuredImage)
    : getImage(headerImage)

  return (
    <ProductStyles>
      <ProductLink {...feature}>
        <GatsbyImage
          className="features__item--img"
          image={image}
          alt="Product Image"
        />
        {title && introduction && (
          <div className="features__item--content">
            {title && <h4>{title}</h4>}
            <Button text="Learn more" as="span" arrow={true} />
          </div>
        )}
      </ProductLink>
    </ProductStyles>
  )
}

export default Product
