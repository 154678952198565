import styled from "styled-components"
import {
  RolesWrapperStyles,
  SkillsWrapperStyles,
} from "../BasicTextModule/BasicTextModuleStyles"

export const ParallaxResumeStyles = styled.section`
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  ul {
    margin: 1rem 0;
    padding: 0 1rem;
    li {
      padding: 0;
    }
    p {
      color: #fff;
      margin: 0;
    }
  }

  .intro {
    width: 80vw;
    margin: 0 auto;
  }
  .location {
    color: var(--bodyColor);
  }
  .time {
    text-align: right;
  }
  .background {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: radial-gradient(var(--background) 25%, transparent 50%),
      radial-gradient(var(--bodyColor) 25%, transparent 50%),
      radial-gradient(var(--inActive) 25%, transparent 50%),
      radial-gradient(var(--primary) 25%, transparent 50%);
    background-size: 60% 80%, 70% 80%, 80% 80%, 80% 80%;
    background-repeat: repeat-y;
    transform: scale(3);
    overflow: hidden;

    @media (max-width: 768px) {
      width: 100lvw;
      height: 100svh;
    }
  }

  .scrollText {
    font-size: 1.5rem;
  }
  ${RolesWrapperStyles} {
    .roles__content {
      margin: 0;
      padding-bottom: 0;
    }
  }
  ${SkillsWrapperStyles} {
    .skills__content {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
  .content {
    display: block;
    width: 100%;
    padding-bottom: 320px;
    @media (max-width: 768px) {
      min-height: 100lvh;
    }
  }

  .logo {
    margin: 0 auto 64px;
    border-radius: 50%;
    padding: 12px;
    background-color: #fff;
    border: 2px solid var(--primary);
    box-shadow: var(--boxShadow);
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      width: 60%;
      height: 60%;
      object-fit: contain !important;
      object-position: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media (max-width: 768px) {
      width: 100px;
      height: 100px;
    }
  }

  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    height: 10rem;
    width: 25%;
    text-align: center;
    border-radius: 10px;
  }

  .full {
    margin: 0 15%;
  }

  .time {
    color: #fff;
    font-size: 1.2em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--primary);
    margin: 0 0 32px;
    text-shadow: var(--boxShadow);
    font-weight: 100;
    svg {
      margin: 0 8px 4px 0;
    }

    @media (max-width: 750px) {
      justify-content: center;
      font-size: 1em;
    }
  }

  .parallax {
    margin-right: 15%;
  }

  .sticky {
    margin-left: 15%;
    background-color: #ff6d6d;
  }

  @media (max-width: 750px) {
    .card {
      width: 40%;
    }

    .full {
      margin: 0 1.5rem;
    }

    .parallax {
      margin-right: 1.5rem;
    }

    .sticky {
      margin-left: 1.5rem;
    }

    .full.content p {
      margin-bottom: 0;
      &.time {
        margin-bottom: 1rem;
      }
    }

    h1,
    h2,
    .location,
    p {
      padding: 0;
    }
  }
`
